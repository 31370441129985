|<section class="container-fluid bg-body-tertiary team section p-5" data-aos="fade-left" data-aos-duration="2000">
    <div class="px-md-5 m-md-5">
        <div class="row">
            <div class="col">
                <div class="text-center">
                    <img src="assets/images/logo/logo_blue.png" class="img-fluid" style="width: 6rem;" alt="neurodetecai_logo">
                    <div class="row">
                        <h2 class="display-5 fw-medium text-head-blue-1 mb-3">¿Qué es NeuroDetectAI?</h2>
                        <p class="text-p-1 fs-4 my-5">
                            <b>NeuroDetectAI</b> es una plataforma que ofrece software potenciado por inteligencia artificial con el proósito de
                            detectar trastornos de neurodesarrollo a través de la interpretación de distintos biomarcadores y de la experiencia médica.
                        </p>
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col-12 col-md-6">
                        <div class="row px-2 px-md-2 mb-5 mb-md-0">
                            <div class="col-12 col-md-2 text-center text-md-start">
                                <img src="assets/images/idea.png" class="img-fluid img-icon-size" alt="">
                            </div>
                            <div class="col-12 col-md-10 text-center text-md-start">
                                <h2 class="display-6 fw-medium text-head-blue-1">¿Cómo funciona?</h2>
                                <p class="text-p-1 fs-5 mt-3">
                                    Nuestras distintas soluciones funcionan a través de la interpretación de distintos biomarcadores y de la 
                                    experiencia médica. Cada solución recibe los datos del paciente y los procesa a través de algoritmos 
                                    de inteligencia artificial para ofrecer un diagnóstico.
                                </p>
                                <button type="button" class="btn btn-blue border-2 shadow rounded-5 px-4 py-2 mt-3 fs-5 fw-semibold">
                                    Soluciones
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="row px-2 px-md-2 mb-md-5 mb-md-0">
                            <div class="col-12 col-md-2 text-center text-md-start">
                                <img src="assets/images/algorithm.png" class="img-fluid img-icon-size" alt="">
                            </div>
                            <div class="col-12 col-md-10 text-center text-md-start">
                                <h2 class="display-6 fw-medium text-head-blue-1">¿Por qué elegirnos?</h2>
                                <p class="text-p-1 fs-5 mt-3">
                                    Nuestra plataforma que ofrece varias soluciones de software especializado y potenciado por inteligencia 
                                    artificial con el propósito de detectar trastornos de neurodesarrollo y ofrecer un mejor entorno de desarrollo 
                                    para los pacientes.
                                </p>
                                <button type="button" class="btn btn-blue border-2 shadow rounded-5 px-4 py-2 mt-3 fs-5 fw-semibold">
                                    Testimonios
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-center text-center mb-5">
        <div class="col-md-10" data-aos="fade-up">
            <h2 class="display-5 fw-medium text-head-blue-1 mb-5">Respaldado por Expertos</h2>
            <p class="text-p-1 fs-4">Nuestro equipo de profesionales en inteligencia artificial y tecnología garantiza soluciones innovadoras y de alta calidad. Con amplia experiencia y compromiso, estamos preparados para enfrentar cualquier desafío tecnológico.</p>
        </div>
    </div>
    <!-- End Section Title -->

    <div class="container mb-5">
        <div class="row g-4">
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <div class="team-member rounded-4 d-flex align-items-start">
                    <div class="pic"><img src="assets/images/projects/dtdah/team/1.png" class="img-fluid" alt=""></div>
                    <div class="member-info">
                        <h4>Dr. Giner Alor Hernández</h4>
                        <span class="fs-6">Profesor Investigador en el Instituto Tecnológico de Orizaba y miembro SNI 3.</span>
                        <p class="fs-6">Líder en diversas redes de investigación y evaluador de proyectos a nivel nacional e
                            internacional. Sus áreas de interés incluyen Sistemas Inteligentes, Big Data, Internet de
                            las Cosas, Inteligencia Artificial e Ingeniería de Software.</p>
                        <div class="social">
                            <a href=""><i class="bi bi-twitter-x"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i> </a>
                        </div>
                    </div>
                </div>
            </div><!-- End Team Member -->

            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <div class="team-member rounded-4 d-flex align-items-start">
                    <div class="pic"><img src="assets/images/projects/dtdah/team/2.png" class="img-fluid" alt=""></div>
                    <div class="member-info">
                        <h4>Dra. Maritza Bustos López</h4>
                        <span class="fs-6">Doctora en Ciencias de la Ingeniería y postdoctorado en Inteligencia Artificial.</span>
                        <p class="fs-6">Investigadora en el Tecnológico Nacional de México, con enfoque en tecnologías de aprendizaje
                            en educación. Ha participado en proyectos multidisciplinarios y en eventos académicos de
                            prestigio.</p>
                        <div class="social">
                            <a href=""><i class="bi bi-twitter-x"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i> </a>
                        </div>
                    </div>
                </div>
            </div><!-- End Team Member -->

            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                <div class="team-member rounded-4 d-flex align-items-start">
                    <div class="pic"><img src="assets/images/projects/dtdah/team/3.png" class="img-fluid" alt=""></div>
                    <div class="member-info">
                        <h4>Dr. José Luis Sánchez Cervantes</h4>
                        <span class="fs-6">Profesor Investigador en el Instituto Tecnológico de Orizaba y miembro SNI 1.</span>
                        <p class="fs-6">Participa en proyectos de investigación nacionales e internacionales en áreas como
                            Inteligencia Artificial, Sistemas Inteligentes, Big Data e Internet de las Cosas.</p>
                        <div class="social">
                            <a href=""><i class="bi bi-twitter-x"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i> </a>
                        </div>
                    </div>
                </div>
            </div><!-- End Team Member -->

            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                <div class="team-member rounded-4 d-flex align-items-start">
                    <div class="pic"><img src="assets/images/projects/dtdah/team/4.png" class="img-fluid" alt=""></div>
                    <div class="member-info">
                        <h4>Dra. Laura Nely Sánchez Morales</h4>
                        <span class="fs-6">Profesora investigadora en el Instituto Tecnológico de Orizaba, reconocida por el
                            SNI.</span>
                        <p class="fs-6">Sus intereses de investigación incluyen procesamiento de imágenes, redes neuronales, diseño
                            de interfaz de usuario, generación automática de software, aplicaciones móviles y realidad
                            virtual.</p>
                        <div class="social">
                            <a href=""><i class="bi bi-twitter-x"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i> </a>
                        </div>
                    </div>
                </div>
            </div><!-- End Team Member -->

            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                <div class="team-member rounded-4 d-flex align-items-start">
                    <div class="pic"><img src="assets/images/projects/dtdah/team/5.png" class="img-fluid" alt=""></div>
                    <div class="member-info">
                        <h4>Dr. Jonathan Hernández Capistrán</h4>
                        <span class="fs-6">Investigador posdoctoral en el Instituto Tecnológico de Orizaba y miembro SNI.</span>
                        <p class="fs-6">Experto en sistemas inteligentes y visión por computadora, con amplia experiencia en
                            proyectos de diagnóstico médico y tecnología aplicada. Ha publicado en revistas
                            internacionales y registrados programas de cómputo relacionados con el TDAH.</p>
                        <div class="social">
                            <a href=""><i class="bi bi-twitter-x"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i> </a>
                        </div>
                    </div>
                </div>
            </div><!-- End Team Member -->
        </div>
    </div>  
</section>