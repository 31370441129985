<!-- ======= Hero Section ======= -->
<section class="hero-section" id="hero">
    <div class="wave">
        <svg width="100%" height="355px" viewBox="0 0 1920 355" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                    <path
                        d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z"
                        id="Path"></path>
                </g>
            </g>
        </svg>
    </div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 hero-text-image">
                <div class="row">
                    <div class="col-lg-8 text-center text-lg-start">
                        <h1 data-aos="fade-right">{{ title }}</h1>
                        <p class="mb-5" data-aos="fade-right" data-aos-delay="100">{{ description }}</p>
                        <p data-aos="fade-right" data-aos-delay="200" data-aos-offset="-500"><a href="#"
                                class="btn btn-outline-white">Get started</a></p>
                    </div>
                    <div class="col-lg-4 iphone-wrap">
                        <img [src]="imageTitle" class="phone-2" alt="Image" data-aos="fade-right">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section><!-- End Hero -->

<main id="main">
    <!-- ======= Features Section ======= -->
    <section class="section">
        <div class="container">

            <div class="row justify-content-center text-center mb-5">
                <div class="col-md-5" data-aos="fade-up">
                    <h2 class="section-heading">{{ subtitle_1 }}</h2>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4" data-aos="fade-up" data-aos-delay="">
                    <div class="feature-1 text-center">
                        <div class="wrap-icon icon-1">
                            <i [class]="icon_feature_1"></i>
                        </div>
                        <h3 class="mb-3">{{ feature_title_1 }}</h3>
                        <p>{{ description_feature_1 }}</p>
                    </div>
                </div>
                <div class="col-md-4" data-aos="fade-up" data-aos-delay="100">
                    <div class="feature-1 text-center">
                        <div class="wrap-icon icon-1">
                            <i [class]="icon_feature_2"></i>
                        </div>
                        <h3 class="mb-3">{{ feature_title_2 }}</h3>
                        <p>{{ description_feature_2 }}</p>
                    </div>
                </div>
                <div class="col-md-4" data-aos="fade-up" data-aos-delay="200">
                    <div class="feature-1 text-center">
                        <div class="wrap-icon icon-1">
                            <i [class]="icon_feature_3"></i>
                        </div>
                        <h3 class="mb-3">{{ feature_title_3 }}</h3>
                        <p>{{ description_feature_3 }}</p>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!-- ======= End Features ======= -->

    <!-- ======= First Steps Section ======= -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center text-center mb-5" data-aos="fade">
                <div class="col-md-6 mb-5">
                    <img [src]="imageSteps" alt="Image" class="img-fluid rounded-4">
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="step">
                        <span class="number">01</span>
                        <h3>{{step_title_1}}</h3>
                        <p>{{step_description_1}}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="step">
                        <span class="number">02</span>
                        <h3>{{step_title_2}}</h3>
                        <p>{{step_description_2}}</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="step">
                        <span class="number">03</span>
                        <h3>{{step_title_3}}</h3>
                        <p>{{step_description_3}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ======= End First Steps ======= -->

    <!-- ======= More Information Section ======= -->
    <section class="section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-4 me-auto">
                    <h2 class="mb-4">{{ info_title_1 }}</h2>
                    <p class="mb-4">{{ info_description_1 }}</p>
                    <p><a href="#" class="btn btn-primary">Download Now</a></p>
                </div>
                <div class="col-md-6" data-aos="fade-left">
                    <img [src]="imageInfo_1" alt="Image" class="img-fluid rounded-4">
                </div>
            </div>
        </div>
    </section>
    
    <section class="section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-4 ms-auto order-2">
                    <h2 class="mb-4">{{ info_title_2 }}</h2>
                    <p class="mb-4">{{ info_description_2 }}</p>
                    <p><a href="#" class="btn btn-primary">Download Now</a></p>
                </div>
                <div class="col-md-6" data-aos="fade-right">
                    <img [src]="imageInfo_2" alt="Image" class="img-fluid rounded-4">
                </div>
            </div>
        </div>
    </section>
    <!-- ======= End More Information ======= -->

    <!-- ======= Testimonials Section ======= -->
    <section class="section border-top border-bottom">
        <div class="container">
            <div class="row justify-content-center text-center mb-5">
                <div class="col-md-6">
                    <h2 class="section-heading">{{ testimonial_title }}</h2>
                </div>
            </div>
            <div class="row justify-content-center text-center">
                <div class="col-md-7">

                    <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                        <div class="swiper-wrapper">

                            <div class="swiper-slide">
                                <div class="review text-center">
                                    <p class="stars">
                                        <span class="bi bi-star-fill"></span>
                                        <span class="bi bi-star-fill"></span>
                                        <span class="bi bi-star-fill"></span>
                                        <span class="bi bi-star-fill"></span>
                                        <span class="bi bi-star-fill muted"></span>
                                    </p>
                                    <h3>Excellent App!</h3>
                                    <blockquote>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius ea delectus
                                            pariatur, numquam
                                            aperiam dolore nam optio dolorem facilis itaque voluptatum recusandae
                                            deleniti minus animi,
                                            provident voluptates consectetur maiores quos.</p>
                                    </blockquote>

                                    <p class="review-user">
                                        <img src="assets/img/person_1.jpg" alt="Image"
                                            class="img-fluid rounded-circle mb-3">
                                        <span class="d-block">
                                            <span class="text-black">Jean Doe</span>, &mdash; App User
                                        </span>
                                    </p>

                                </div>
                            </div><!-- End testimonial item -->

                            <div class="swiper-slide">
                                <div class="review text-center">
                                    <p class="stars">
                                        <span class="bi bi-star-fill"></span>
                                        <span class="bi bi-star-fill"></span>
                                        <span class="bi bi-star-fill"></span>
                                        <span class="bi bi-star-fill"></span>
                                        <span class="bi bi-star-fill muted"></span>
                                    </p>
                                    <h3>This App is easy to use!</h3>
                                    <blockquote>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius ea delectus
                                            pariatur, numquam
                                            aperiam dolore nam optio dolorem facilis itaque voluptatum recusandae
                                            deleniti minus animi,
                                            provident voluptates consectetur maiores quos.</p>
                                    </blockquote>

                                    <p class="review-user">
                                        <img src="assets/img/person_2.jpg" alt="Image"
                                            class="img-fluid rounded-circle mb-3">
                                        <span class="d-block">
                                            <span class="text-black">Johan Smith</span>, &mdash; App User
                                        </span>
                                    </p>

                                </div>
                            </div><!-- End testimonial item -->

                            <div class="swiper-slide">
                                <div class="review text-center">
                                    <p class="stars">
                                        <span class="bi bi-star-fill"></span>
                                        <span class="bi bi-star-fill"></span>
                                        <span class="bi bi-star-fill"></span>
                                        <span class="bi bi-star-fill"></span>
                                        <span class="bi bi-star-fill muted"></span>
                                    </p>
                                    <h3>Awesome functionality!</h3>
                                    <blockquote>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius ea delectus
                                            pariatur, numquam
                                            aperiam dolore nam optio dolorem facilis itaque voluptatum recusandae
                                            deleniti minus animi,
                                            provident voluptates consectetur maiores quos.</p>
                                    </blockquote>

                                    <p class="review-user">
                                        <img src="assets/img/person_3.jpg" alt="Image"
                                            class="img-fluid rounded-circle mb-3">
                                        <span class="d-block">
                                            <span class="text-black">Jean Thunberg</span>, &mdash; App User
                                        </span>
                                    </p>

                                </div>
                            </div><!-- End testimonial item -->
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
            </div>
        </div>
    </section><!-- End Testimonials Section -->

    <!-- ======= CTA Section ======= -->
    <section class="section cta-section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 me-auto text-center text-md-start mb-5 mb-md-0">
                    <h2>{{ team_title }}</h2>
                    <p>{{ team_description }}</p>
                </div>
                <div class="col-md-5 text-center text-md-end">
                    <p>
                        <a href="#" class="btn d-inline-flex align-items-center">
                            <i class="bi bi-people-fill me-2"></i><span>Conoce al equipo</span>
                        </a>
                        <a href="#" class="btn d-inline-flex align-items-center ms-2">
                            <i class="bi bi-newspaper me-2"></i><span>Visita nuestro Blog</span>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </section>
    <!-- End CTA Section -->
    <div class="section" id="contactus">
        <app-contact-us />
    </div>
</main><!-- End #main -->