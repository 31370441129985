<footer class="container-fluid gradient-secondary pt-5 pb-1 px-5">
    <div class="row px-md-5 mx-md-5">
        <div class="col-6 col-md-3 mb-3 text-white fs-5">
            <h5 class="fs-4 fw-medium">Section</h5>
            <ul class="nav flex-column">
                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-white">Home</a></li>
                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-white">Features</a></li>
                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-white">Pricing</a></li>
                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-white">FAQs</a></li>
                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-white">About</a></li>
            </ul>
        </div>

        <div class="col-6 col-md-3 mb-3 text-white fs-5">
            <h5 class="fs-4 fw-medium">Section</h5>
            <ul class="nav flex-column">
                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-white">Home</a></li>
                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-white">Features</a></li>
                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-white">Pricing</a></li>
                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-white">FAQs</a></li>
                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-white">About</a></li>
            </ul>
        </div>

        <div class="col-md-5 offset-md-1 mb-3">
            <form class="text-white">
                <h5 class="fs-4 fw-medium">Subscribe to our newsletter</h5>
                <p class="fs-5">Monthly digest of what's new and exciting from us.</p>
                <div class="d-flex flex-column flex-sm-row w-100 gap-2">
                    <label for="newsletter1" class="visually-hidden">Email address</label>
                    <input id="newsletter1" type="text" class="form-control shadow" placeholder="Email address" spellcheck="false" data-ms-editor="true">
                        <button type="button" class="btn btn-light border-2 shadow rounded-5 px-4 py-1 fs-5 fw-semibold">
                            Subscribe
                        </button>
                </div>
            </form>
        </div>
    </div>

    <div class="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top text-white fs-5">
        <p class="fw-medium">© 2024 NeuroDetectAI, Inc. All rights reserved.</p>
        <ul class="list-unstyled d-flex">
            <i class="bi bi-facebook fs-4 ms-3"></i>
            <i class="bi bi-twitter fs-4 ms-3"></i>
            <i class="bi bi-linkedin fs-4 ms-3"></i>
        </ul>
    </div>
</footer>