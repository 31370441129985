<section class="container-fluid pt-5 px-md-5 pb-3">
    <div class="marketing m-md-5 px-md-5" data-aos="" data-aos-duration="1000">
        <div class="row px-md-5">
            <div class="col">
                <div class="row text-center mb-3">
                    <h2 class="display-5 fw-medium text-head-blue-1">Preguntas Frecuentes</h2>
                    <p class="text-p-1 fs-4 my-3">
                        Explora nuestras respuestas rápidas a las consultas más comunes y obtén una mejor comprensión de 
                        nuestros servicios. Si necesitas más información, estamos aquí para ayudarte.
                    </p>
                </div>
                <div class="row">
                    <div class="col-12 col-md-8 mb-5 mb-md-0">
                        <div class="accordion shadow" id="accordionFAQ">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        1. ¿Pregunta número uno?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                    data-bs-parent="#accordionFAQ">
                                    <div class="accordion-body fs-5">
                                        Respuesta a la pregunta número uno. Aquí va el contenido detallado de la respuesta.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        2. ¿Pregunta número dos?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionFAQ">
                                    <div class="accordion-body fs-5">
                                        Respuesta a la pregunta número dos. Proporciona aquí la explicación pertinente.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        3. ¿Pregunta número tres?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                    data-bs-parent="#accordionFAQ">
                                    <div class="accordion-body fs-5">
                                        Respuesta a la pregunta número dos. Proporciona aquí la explicación pertinente.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        4. ¿Pregunta número cuatro?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                    data-bs-parent="#accordionFAQ">
                                    <div class="accordion-body fs-5">
                                        Respuesta a la pregunta número dos. Proporciona aquí la explicación pertinente.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        5. ¿Pregunta número cinco?
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                                    data-bs-parent="#accordionFAQ">
                                    <div class="accordion-body fs-5">
                                        Respuesta a la pregunta número dos. Proporciona aquí la explicación pertinente.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 d-flex align-items-center justify-content-center">
                        <img src="assets/images/robot.png" class="img-fluid w-50" alt="faq">
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center mt-5">
            <p class="text-p-1 fs-4 mt-3">
                ¿No aparece tu pregunta? Contáctanos y te ayudaremos a resolver cualquier duda que tengas.
            </p>
        </div>
    </div>
</section>